import React, { useEffect } from 'react';
import logofx from './images/FX.png';
import poweredby from './images/poweredby-fx.png';
import arrows from './images/arrows.svg';
import iconCloud from './images/iconsMethod/28.png';
import iconCodeScreen from './images/iconsMethod/29.png';
import iconScreen from './images/iconsMethod/20.png';
import myself from './images/myself-whois.png';
import ebook from './images/ebook.png';
import ebookWeb from './images/ebook-web.png';
import ebookMobile from './images/ebook-mobile.png';

import './css/App.css';
import './css/Container-header.css';
import './css/Container-whois.css';
import './css/Container-method.css';
import './css/Container-content.css';
import './css/Container-how.css';
import './css/Footer.css';
import './css/Price-section.css';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <div className='first-part'>
          <div className="Title" id='Title-position'>
            <div className='logo'>
              <img src={logofx} id="logo" alt="logo" />
            </div>
            <p className="Title-style" id='title'>
              Um passo à frente na <span id='title-change'>Inteligencia Artificial</span>
            </p>
            <p className="subtitle" id='subtitle'>
              Imagine dominar as tecnologias que estão moldando o futuro
            </p>
          
            <a className="Btn-invite" id='btn-invite' href='#purchase'>
              Quero meu exemplar agora 📚
            </a>
          </div>
          <div className='container-arrows'>
            <img className='arrows' src={arrows} alt='arrows'></img>
          </div>
        </div>
      
        <div className='container-box-content'>
          <div className='container-content'>
            <div className='content-wrapper'>
              <div className='text-content'>
                <div className='title-content'>
                  <p className='Title-style-content'>
                    O que você vai aprender?
                  </p>
                </div>
                <div className='subtitle-container-content'>
                  <div className='subtitle-box-content'>
                    <div className='subtitle-content' id='subtitle-content'>
                      <p className='subtitle-style-content' id='subtitle-style-content'>
                        <span id='hifen-topic'>{'>'}</span> Fundamentos da Inteligência Artificial e Machine Learning
                      </p>
                    </div>
                    <div className='subtitle-content' id='subtitle-content'>
                      <p className='subtitle-style-content' id='subtitle-style-content'>
                        <span id='hifen-topic'>{'>'}</span> Modelos de Linguagem Grande (LLMs) e como eles estão transformando o mercado
                      </p>
                    </div>
                    <div className='subtitle-content' id='subtitle-content'>
                      <p className='subtitle-style-content' id='subtitle-style-content'>
                        <span id='hifen-topic'>{'>'}</span> Se destacar no mercado com habilidades que poucos têm.
                      </p>
                    </div>
                    <div className='subtitle-content' id='subtitle-content'>
                      <p className='subtitle-style-content' id='subtitle-style-content'>
                        <span id='hifen-topic'>{'>'}</span> Processos avançados de Treinamento e Fine-Tuning em LLMs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='image-container'>
                <img src={ebook} className="book-image" alt="Ebook" />
              </div>
            </div>
          </div>
        </div>



        <hr id='divider-box'/>

        <div className='container-box-how'>
          <div className='container-how'>    
              <div className='title-how'>
                <p className='Title-style-how'>
                  O que você ganha com esse ebook?
                </p>
              </div>
              <div className='how-body'>
                <div className='how-is-description' id='#how-is-description'>      
                  <p>
                  Imagine dominar as tecnologias que estão moldando o futuro da Inteligência Artificial. 
                  </p>
                  <br></br>
                  <p>
                  Aqui você terá em suas mãos um guia prático e avançado sobre IA e Modelos de Linguagem de Grande Escala (LLMs). 
                  Além de entender os conceitos de IA e Machine Learning, você entenderá como aplicar esse conhecimento em projetos reais.
                  </p>
                  <br></br>
                  <p>
                  Se você deseja ficar à frente da concorrência, se capacitar nas principais ferramentas e tendências de IA. Comece agora e transforme seu conhecimento em uma vantagem competitiva!
                  </p>
                </div>
              </div> 
          </div>
        </div>

        <div className='Container-box-method'>
          <div className='Container-method'>
            <div className='title-method'>
              <p className='Title-style-method'>
                Base de Conhecimento
              </p>
            </div>
            <div className='modules'> 
              <div className='first'>
                <div className='box-title-cards'>
                  <img src={iconCloud} className='icons-method' alt='icon'></img>
                  <span className='title-cards'>LLMs</span>
                  <div className='subtitle-cards'>
                    <span>Explore o poder dos LLMs e aprenda a aplicá-los para processar e gerar textos complexos de forma eficiente.</span>
                  </div>
                </div>
              </div>
              <div className='first'>
                <div className='box-title-cards'>
                  <img src={iconCodeScreen} className='icons-method' alt='icon'></img>
                  <span className='title-cards'>RAG</span>
                  <div className='subtitle-cards'>
                    <span>Descubra como combinar busca de informações e geração de texto para criar soluções personalizadas e inteligentes.</span>
                  </div>
                </div>
              </div>
              <div className='second'>
                <div className='box-title-cards'>
                  <img src={iconScreen} className='icons-method' alt='icon'></img>
                  <span className='title-cards'>Redes Neurais</span>
                  <div className='subtitle-cards'>
                    <span>Entenda como as redes neurais funcionam e como elas podem ser aplicadas em IA para resolver problemas complexos.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='ebook-container'>
          <picture>
            <source media="(max-width: 767px)" srcSet={ebookMobile} />
            <img src={ebookWeb} className="ebook-image" alt="Ebook" />
          </picture>
        </div>

        <hr id='footer-divider'/>

        <div class='Who-is'>
          <div class='container-whois'>
            <div class='image-container'>
              <img class='photo' src={myself} alt='instaIcon' />
            </div>
            <div class='container'>
              <div class='title-whois'>
                <p class='Title-style-whois'>Deixa eu me apresentar</p>
              </div>
              <div class='who-is-description'>
                <p class='welcome-message'>Primeiramente, seja muito bem-vindo.</p>
                <p class='author-description'>
                  Sou Felipe Xavier, cientista de dados e pesquisador de IA. Já trabalhei
                  como arquiteto de software na maior consultoria de tecnologia do mundo,
                  liderando e construindo projetos para grandes empresas.
                </p>
                <p class='author-description'>
                  Hoje, estou totalmente focado em Inteligência Artificial e seus impactos no futuro da tecnologia. Este ebook reflete essa jornada e foi feito para quem deseja dominar IA e LLMs de forma prática e eficaz.
                </p>
              </div>
            </div>
          </div>
        </div>


        <div class="price-box" id="purchase">
          <div class="price-content">
            <div class="left-content">
              <h3 class="title">Garanta o seu hoje</h3>
              <p class="installments">Por apenas</p>
              <div class="price-value">
                <span class="currency">R$</span>
                <span class="value">19,90</span>
              </div>
              {/* <p class="single-payment">ou R$ 997 à vista</p> */}
              <a href="https://pay.kiwify.com.br/n7OMFGv" target="_blank" class="cta-button">
                Garantir minha vaga
              </a>
              <p class="limited-offer">não perca! acessos limitados</p>
            </div>
            <div class="right-content">
              <ul class="benefits-list">
                <li>Acesso vitalício</li>
                <li>Todo o conteúdo do Blackbook</li>
                <li>Atualizações gratuitas</li>
                <li>IA básico ao avançado</li>
                {/* <!-- <li>Comunidade ativa e exclusiva</li> --> */}
                <li>Suporte diretamente comigo</li>                
                <li>7 dias de garantia incondicional</li>
              </ul>
            </div>
          </div>
        </div>

        <hr id='footer-divider'/>
        <div className='footer'>
          <div className='poweredby-logo'>
              <img src={poweredby} id="poweredby-logo" alt="poweredby" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
